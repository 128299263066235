<template>
    <div>
        <div class="w-100">
            <b-tabs
                pills
                class="mb-0"
                nav-class="mb-0"
                active-nav-item-class="text-danger"
                nav-wrapper-class="rounded text-light tabsContainer mb-0 bg-secondaryColor"
            >
                <b-tab>
                    <template #title>
                        <p class="mb-0 text-light">Tab contents 1</p>
                    </template>
                </b-tab>
                <b-tab>
                    <template #title>
                        <p class="mb-0 text-light">Tab contents 1</p>
                    </template>
                </b-tab>
            </b-tabs>
        </div>

        <b-card class="mt-2"> Hello </b-card>
    </div>
</template>

<script>
export default {
    name: 'prototype',
};
</script>

<style scoped></style>
